<template>
  <div class="admin-dashboard">
    <AdminHeader />
    <main>
      <b-container>
        <div class="mt-5">
          <b-card>
            <b-card-title>Candidate Endorsements</b-card-title>
            <b-tabs card>
              <b-tab title="Download" active>
                <DownloadEndorsements />
              </b-tab>
              <b-tab title="Upload">
                <UploadEndorsements />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </b-container>
    </main>
  </div>
</template>

<script>
import AdminHeader from "@/components/admin/Header.vue";
import DownloadEndorsements from "@/components/admin/DownloadEndorsements.vue";
import UploadEndorsements from "@/components/admin/UploadEndorsements.vue";
export default {
  name: "CandidateReport",
  components: {
    AdminHeader,
    DownloadEndorsements,
    UploadEndorsements
  }
};
</script>
